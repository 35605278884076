import { favoriteService } from 'services/FavoriteService';
import { useAuthCheck } from './useAuthCheck';
import { useEffect, useState } from 'react';
import { useFavoriteStore } from 'states/useFavorite.store';
import { analyticsService } from 'services/AnalyticsService';
import {
  ExperienceSelectAndWishlistEventPayload,
  SelectAndWishlistEventPayload,
} from 'types/analytics.type';

export function useFavorite(
  isFavorited: boolean,
  analyticsPayload?: SelectAndWishlistEventPayload,
  experienceAnalyticsPayload?: ExperienceSelectAndWishlistEventPayload
) {
  const [isCurrentFavorited, setIsCurrentFavorited] = useState(isFavorited);
  const { checkLoggedIn } = useAuthCheck();
  const {
    setFavoritedVehicleIds,
    favoritedVehicleIds,
    setFavoritedExperienceIds,
    favoritedExperienceIds,
  } = useFavoriteStore();

  useEffect(() => {
    setIsCurrentFavorited(isFavorited);
  }, [isFavorited]);

  const toggleFavorite = async (vehicleId: number) => {
    if (!checkLoggedIn()) {
      return;
    }
    try {
      setIsCurrentFavorited((f) => !f);
      const notChangedState = isCurrentFavorited;
      if (notChangedState) {
        await favoriteService.removeFavorite(vehicleId);
        const itemIdx = favoritedVehicleIds.findIndex((v) => v === vehicleId);
        if (~itemIdx) {
          const newFavoritedVehicles = [...favoritedVehicleIds];
          newFavoritedVehicles.splice(itemIdx, 1);
          setFavoritedVehicleIds(newFavoritedVehicles);
        }
      } else {
        await favoriteService.addFavorite(vehicleId);
        analyticsPayload && analyticsService.addToFavorite(analyticsPayload);
        setFavoritedVehicleIds([...favoritedVehicleIds, vehicleId]);
      }
    } catch {
      setIsCurrentFavorited((f) => !f);
    }
  };

  const toggleExperienceFavorite = async (vehicleId: number) => {
    if (!checkLoggedIn()) {
      return;
    }
    try {
      setIsCurrentFavorited((f) => !f);
      const notChangedState = isCurrentFavorited;
      if (notChangedState) {
        await favoriteService.removeExperienceFavorite(vehicleId);
        const itemIdx = favoritedExperienceIds.findIndex(
          (v) => v === vehicleId
        );
        if (~itemIdx) {
          const newFavoritedExperiences = [...favoritedExperienceIds];
          newFavoritedExperiences.splice(itemIdx, 1);
          setFavoritedExperienceIds(newFavoritedExperiences);
        }
      } else {
        await favoriteService.addExperienceFavorite(vehicleId);
        experienceAnalyticsPayload &&
          analyticsService.addToFavoriteExperience(experienceAnalyticsPayload);
        setFavoritedExperienceIds([...favoritedExperienceIds, vehicleId]);
      }
    } catch {
      setIsCurrentFavorited((f) => !f);
    }
  };

  return {
    toggleFavorite,
    toggleExperienceFavorite,
    isCurrentFavorited,
  };
}
